import axios from 'axios';
const apiBaseUrl = import.meta.env.VITE_APIURL;

export default class ImageUploadService {
  private static instance: ImageUploadService;

  private constructor() { }

  static getInstance(): ImageUploadService {
    if (!ImageUploadService.instance) {
      ImageUploadService.instance = new ImageUploadService();
    }

    return ImageUploadService.instance;
  }

  static async uploadImages(panelid: number, fileList: any): Promise<void> {
    if (fileList.length == 0) {
      throw new Error('No files in fileList');
    }

    // for every file, make a separate request and wait for all to complete or throw error if one fails
    let formdata: any = new FormData();
    for (var i = 0; i < fileList.length; i++) {
      formdata.append(`files`, fileList[i].file, fileList[i].file.name);
    }
    formdata.append('data', JSON.stringify({}));

    let res_file: any;

    try {
      res_file = await axios.post(`${apiBaseUrl}upload`, formdata, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formdata._boundary}`,
        },
      });
    } catch (error: any) {
      throw error;
    }

    Promise.all(res_file.data.map(async (file: any) => {
      let data = {
        "img": file.id,
        "solar_panel": panelid
      }

      let res_modelimport: any;
      try {
        res_modelimport = await axios.post(`${apiBaseUrl}recognition-images`, { data });
      } catch (error: any) {
        throw error;
      }
    }));

  }
}
