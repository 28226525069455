<script setup lang="ts">
  import IconCheck from '~icons/fe/check';
  import IconClose from '~icons/fe/close';
  import IconWarn from '~icons/fe/warning';
  import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue';

  const props = defineProps({
    open: Boolean,
    appearance: String, //"success" | "error" | "warning"
    title: String,
  });
  const emit = defineEmits(['close']);

  const isOpen = ref(false);

  const openModal = () => {
    isOpen.value = true;
  };
  const closeModal = () => {
    isOpen.value = false;
    emit('close');
  };

  watch(
    () => props.open,
    async (newVal, _oldVal) => {
      if (newVal == true) {
        openModal();
      } else {
        closeModal();
      }
    },
    {
      immediate: true,
    }
  );
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" :open="isOpen" @close="closeModal">
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="translate-y-2 opacity-0"
            enter-to="translate-y-0 opacity-100"
            leave="duration-200 ease-in"
            leave-from="translate-y-0 opacity-100"
            leave-to="translate-y-2 opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="duration-200 ease-out"
            enter-from="opacity-0 translate-y-5"
            enter-to="opacity-100 translate-y-0"
            leave="duration-200 ease-in"
            leave-from="opacity-100 translate-y-0"
            leave-to="opacity-0 translate-y-5"
          >
            <div
              class="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded"
            >
              <div class="px-4 py-5">
                <DialogTitle as="h3" class="text-xl font-medium leading-6 text-slate-900">
                  <div v-if="props.appearance" class="float-left text-2xl mr-1">
                    <IconCheck v-if="props.appearance == 'success'"></IconCheck>
                    <IconClose v-if="props.appearance == 'error'"></IconClose>
                    <IconWarn v-if="props.appearance == 'warning'"></IconWarn>
                  </div>
                  <div class="leading-7 pt-[1px]">{{ props.title }}</div>
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-base p-1 text-slate-600">
                    <slot></slot>
                  </p>
                </div>
              </div>
              <div class="px-4 py-3 bg-slate-100 text-right flex justify-end">
                <slot name="buttons">
                  <form @submit.prevent="closeModal()">
                    <button type="submit" class="button w-min">
                      <span class="button-icon">
                        <IconClose></IconClose>
                      </span>
                      Close
                    </button>
                  </form>
                </slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<style scoped>
  ::v-deep(button.button),
  ::v-deep(a.button) {
    @apply inline-flex;
  }
</style>
