<script setup lang="ts">
//  import AutoComplete from '@/components/common/table/TableAutoComplete.vue';
import { Ref } from 'vue';
import IconCheck from '~icons/bi/check';

interface Props {
  items: { [key: string]: string };
  modelValue: string | null;
}

const props = defineProps<Props>();

const items: Ref<{ [key: string]: string }> = ref({});
watch(
  () => props.items,
  () => {
    items.value = Object.assign(props.items, { '0': '' });
  },
  {
    immediate: true,
  }
);

let refModelValue = ref(props.modelValue ?? '0');

// define emits
const emit = defineEmits(['update:modelValue', 'updateQuery', 'save']);

watch(refModelValue, (newVal, _oldVal) => {
  emit('update:modelValue', newVal);
});
</script>
<template>
  <div class="flex h-9 -mt-[0.55rem] -mb-2 -ml-4 relative">
    <form @submit.prevent="emit('save')" class="w-full">
      <div>
        <TableAutoComplete :items="items" v-model="refModelValue"
          @updateQuery="(val: any) => emit('updateQuery', val)" />
      </div>
      <button v-if="refModelValue !== '0'" type="submit"
        class="h-[2.375rem] w-[2.375rem] cursor-pointer flex absolute right-0 top-0 items-center justify-center">
        <IconCheck class="h-6 w-6 p-[0.125rem] bg-slate-700 text-white rounded-full block" />
      </button>
    </form>
  </div>
</template>
<style scoped>
div:focus-within>button {
  opacity: 1;
}
</style>
