import { defineStore } from 'pinia';

export const usePagesStore = defineStore('pages', {
  state: () => {
    return {
      currentPage: '',
    };
  },
});
export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      authenticated: false,
    };
  },
});
export const useSocketStore = defineStore('socket', {
  state: () => {
    return {
      socket: null,
    };
  },
});
