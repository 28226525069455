<script setup lang="ts">
  import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
  import CheckIcon from '~icons/bx/check';
  import SelectorIcon from '~icons/gg/select';

  interface SelectElements {
    items: Map<string | number, string | number>;
    modelValue: string | number;
  }

  const props = defineProps<SelectElements>();
  const emit = defineEmits(['update:modelValue']);

  const selectedItem = ref(props.modelValue);

  watch(
    () => props.modelValue,
    () => {
      selectedItem.value = props.modelValue;
    }
  );

  watch(
    selectedItem,
    (newVal, _oldVal) => {
      emit('update:modelValue', newVal);
    },
    { immediate: true }
  );
</script>
<template>
  <div class="w-full">
    <Listbox v-model="selectedItem">
      <div class="relative">
        <ListboxButton class="input-text">
          <span class="block truncate pr-6 text-left">{{
            props.items.get(selectedItem) ? props.items.get(selectedItem) : 'Choose...'
          }}</span>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon class="w-5 h-5 text-slate-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute w-full bg-white rounded-md py-2 shadow-centered sm:text-sm focus:outline-none z-50">
            <ListboxOption v-slot="{ active, selected }" v-for="[key, item] in props.items" :key="key" :value="key" as="template">
              <li :class="[active ? 'bg-slate-50' : '', 'text-slate-900 cursor-default select-none relative py-2 pl-10 pr-4']">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ item }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-600">
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>
