<script setup lang="ts">
import { Ref } from 'vue';
import axios from 'axios';
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import ImageUploadService from '@/services/ImageUploadService';
import IconLoading from '~icons/eos-icons/loading';

const apiBaseUrl = import.meta.env.VITE_APIURL;


const filePond = vueFilePond(FilePondPluginFileValidateType);
const filePondRef: any = ref(null);
const uploadImages = ref('');
const loadingImages = ref(false);
const errorOpen = ref(false);
const errorMsg = ref('');
const successOpen = ref(false);
const successTitle = ref('');
const successMsg = ref('');

const recognitionState: Ref<string> = ref('unrecognized');

const closeSuccess = () => {
    successOpen.value = false;
};
const closeError = () => {
    errorOpen.value = false;
};

const submitImages = async () => {

    if (filePondRef.value !== null) {
        loadingImages.value = true;

        const panelid = await postPanel(recognitionState.value);
        const pondFiles = filePondRef.value.getFiles();

        if (pondFiles.length == 0) {
            return;
        }

        try {
            ImageUploadService.uploadImages(panelid, pondFiles);

            uploadImages.value = '';
            filePondRef.value.removeFiles();

            //successOpen.value = true;
            //successTitle.value = 'Success';
            //successMsg.value = 'Images are uploading in the background...';

        } catch (error: any) {
            uploadImages.value = '';
            filePondRef.value.removeFiles();

            errorOpen.value = true;
            errorMsg.value = 'There has been a problem during the upload: ' + error;
        }

        loadingImages.value = false;
    }
};

const postPanel = async (recognition_state: string) => {
    let data: any = {
        recognition_status: recognition_state,
    };
    const out = await axios.post(apiBaseUrl + 'solarpanels/', { data: data });
    return out.data.data.documentId;
};


</script>
<template>
    <div>
        <div>
            <file-pond class="m-0 border-none bg-transparent w-full h-full absolute inset-0 max-h-none"
                name="import-upload-zone" ref="filePondRef" label-idle="Drop .jpg or .png files for recognition"
                v-bind:allow-multiple="true" accepted-file-types="image/png, image/jpeg" v-bind:files="uploadImages"
                v-on:addfile="" credits="false" instant-upload="false" allow-process="false"
                @drop.prevent="submitImages()"></file-pond>
        </div>


        <Teleport to="body">
            <DialogPopup :open="errorOpen" @close="closeError()" :appearance="'error'" :title="'Error'">
                {{ errorMsg }}
            </DialogPopup>
            <DialogPopup :open="successOpen" @close="closeSuccess()" :appearance="'success'" :title="successTitle">
                {{ successMsg }}
            </DialogPopup>
            <div class="absolute right-5 bottom-5 h-14 w-14 p-2 bg-white shadow-md z-50 rounded-full transition-all duration-150"
                :class="{ 'opacity-100 pointer-events-auto translate-y-0': loadingImages, 'opacity-0 pointer-events-none translate-y-4': !loadingImages }">
                <IconLoading class="text-slate-900 w-10 h-10"></IconLoading>
            </div>
        </Teleport>
    </div>
</template>
