<script setup lang="ts">
import IconConnection from '~icons/ph/wifi-high-bold';
import IconNoConnection from '~icons/ph/wifi-slash-bold';
import axios from 'axios';

const apiBaseUrl = import.meta.env.VITE_APIURL;

const checkConnection = async () => {
  // 0: Panel DB offline, 1: Recognition Service offline, 2: both online
  try {
    const response = await axios.get(apiBaseUrl + 'solarpanel/recognition-service-state');
    return response.data ? 2 : 1;
  } catch (error) {
    return 0;
  }
};

const recognitionServiceStatus = ref(0);

const checkStatus = async () => {
  recognitionServiceStatus.value = await checkConnection();
};

onMounted(() => {
  checkStatus();
});

const refreshStatus = () => {
  checkStatus();
};

const refreshInterval = setInterval(refreshStatus, 10000);

onUnmounted(() => {
  clearInterval(refreshInterval);
});
</script>
<template>
  <div>
    <div v-if="recognitionServiceStatus === 2"
      class="rounded px-2 py-1 text-sm w-min border whitespace-nowrap flex items-center cursor-default border-green-700 text-green-700">
      All services online
      <IconConnection class="inline-block w-5 h-5 ml-1" />
    </div>
    <div v-else-if="recognitionServiceStatus === 1"
      class="rounded px-2 py-1 text-sm w-min border whitespace-nowrap flex items-center cursor-default border-slate-500 text-slate-500">
      Recognition Service offline
      <IconNoConnection class="inline-block w-5 h-5 ml-1" />
    </div>
    <div v-else-if="recognitionServiceStatus === 0"
      class="rounded px-2 py-1 text-sm w-min border whitespace-nowrap flex items-center cursor-default border-red-700 text-red-700">
      Panel DB offline
      <IconNoConnection class="inline-block w-5 h-5 ml-1" />
    </div>
  </div>
</template>
