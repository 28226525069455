<script setup lang="ts">
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, ComboboxButton } from '@headlessui/vue';
import CheckIcon from '~icons/bx/check';
import SelectorIcon from '~icons/gg/select';
import IconCancel from '~icons/fe/close';

interface SelectElements {
  items: Map<string | number, string | number>;
  modelValue: string | number;
  cancelButton?: boolean;
  minBoxWidth?: string;
  classList?: string;
}

const props = defineProps<SelectElements>();
const emit = defineEmits(['update:modelValue', 'focus']);

const selectedItem = ref(props.modelValue);
const query = ref(props.modelValue ?? '');

const itemList = ref(props.items);

watch(
  () => props.modelValue,
  () => {
    selectedItem.value = props.modelValue;
  }
);

watch(
  selectedItem,
  (newVal, _oldVal) => {
    emit('update:modelValue', newVal);
  },
  { immediate: true }
);

const displayValue = computed(() => {
  return props.items.get(selectedItem.value) ?? query.value;
});

watch(
  () => props.items,
  () => {
    filterItemList(query.value ? query.value + '' : '');
  }
);

const clearInput = () => {
  selectedItem.value = '';
  query.value = '';
  filterItemList('');
};

const filterItemList = (searchString: string) => {
  if (searchString.length > 0) {
    const filteredItems = new Map<string | number, string | number>();
    props.items.forEach((value, key) => {
      if (typeof value === 'string' && value.toLowerCase().includes(searchString.toLowerCase())) {
        filteredItems.set(key, value);
      } else if (typeof value === 'number' && value.toString().startsWith(searchString)) {
        filteredItems.set(key, value);
      }
    });
    itemList.value = filteredItems;
  } else {
    itemList.value = props.items;
  }
};
</script>
<template>
  <div class="w-full">
    <Combobox v-model="selectedItem" :title="selectedItem">
      <div class="relative">
        <ComboboxInput class="w-full" :class="props.classList ?? 'input-text'" @change="e => {
            query = e.target.value;
            filterItemList(query);
          }
          " @focus="emit('focus')" :displayValue="() => {
              return displayValue + '';
            }
            " placeholder="Search...">
          <ComboboxButton
            class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none outline-hidden border-none">
            <SelectorIcon class="w-5 h-5 text-slate-400" aria-hidden="true" />
          </ComboboxButton>
        </ComboboxInput>
        <div v-if="props.cancelButton && (query + '').length > 0"
          class="absolute inset-y-0 right-0 flex items-center outline-hidden border-none z-10">
          <button @click="clearInput()"
            class="text-slate-400 hover:text-slate-500 px-2 cursor-pointer h-full focus:outline-none focus:text-slate-500 transition duration-150 ease-in-out">
            <IconCancel class="w-5 h-5" aria-hidden="true" />
          </button>
        </div>

        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
          leave-to-class="opacity-0">
          <ComboboxOptions
            class="absolute w-full bg-white rounded-md py-2 shadow-centered sm:text-sm focus:outline-hidden z-50 max-h-64 overflow-auto mt-[1px] -ml-[1px]"
            :style="{ minWidth: props.minBoxWidth }">
            <ComboboxOption v-for="[key, item] in itemList" :key="key" :value="key" v-slot="{ selected, active }"
              as="template">
              <li
                :class="[active ? 'bg-slate-50' : '', 'text-slate-900 cursor-default select-none relative py-2 pl-10 pr-4']"
                :title="item + ''">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate leading-5 h-5']">{{ item
                  }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-600">
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </transition>
      </div>
    </Combobox>
  </div>
</template>
