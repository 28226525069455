<script setup lang="ts">
  import IconClear from '~icons/ic/outline-clear';
  import { PopoverPanel } from '@headlessui/vue';

  interface PopoutFilterProps {
    operators: Map<string, string>;
    modelValue: { operator: string; expression: string };
    openPosition?: 'left' | 'center' | 'right';
  }

  const props = defineProps<PopoutFilterProps>();

  const openPosition = props.openPosition ? props.openPosition : 'left';

  const filterValue = ref(props.modelValue.expression);
  const selectedFilterOperator = ref(props.modelValue.operator);

  const removeFilter = () => {
    filterValue.value = '';
    emitUpdate();
  };

  const emit = defineEmits(['update:modelValue']);

  const filterData = computed(() => {
    return {
      operator: selectedFilterOperator.value,
      expression: filterValue.value,
    };
  });

  const emitUpdate = () => {
    emit('update:modelValue', filterData.value);
  };
</script>
<template>
  <transition
    enter-active-class="transition duration-100 ease-out"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-75 ease-in"
    leave-from-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <PopoverPanel
      class="absolute w-60 bg-white rounded-md p-3 shadow-centered focus:outline-none focus:z-50"
      :class="{
        'left-0 origin-top-left': openPosition == 'right',
        'left-2 -translate-x-1/2 origin-top-center': openPosition == 'center',
        'right-0 origin-top-right': openPosition == 'left',
      }"
    >
      <SelectBox v-model="selectedFilterOperator" :items="props.operators" class="mb-3 relative z-10"></SelectBox>
      <div class="relative mb-3">
        <input v-model="filterValue" name="filterValue" type="text" class="input-text" placeholder="Filter..." />
        <div v-if="filterValue.length > 0" href="" class="block absolute right-1 top-1 p-2" @click="removeFilter()">
          <IconClear class="text-lg text-slate-900"></IconClear>
        </div>
      </div>
      <a href="#" class="button w-full" @click="emitUpdate()"> Apply filter </a>
    </PopoverPanel>
  </transition>
</template>
