<script setup lang="ts">
import { usePagesStore } from '@/store';
import { Ref, isProxy, toRaw } from 'vue';
import axios from 'axios';
import qs from 'qs';
import IconLoading from '~icons/eos-icons/loading';
import IconLink from '~icons/ci/external-link';
import IconChevronLeft from '~icons/material-symbols/chevron-left';
import IconChevronRight from '~icons/material-symbols/chevron-right';
import { useRouter, useRoute } from 'vue-router';

const route = useRoute();
const router = useRouter();

// Props
interface tablePropTypes {
  endpoint: string;
  columns: Array<{
    label: string;
    field: string;
    width?: string;
    empty?: boolean; // if true, the field's value will not be loaded from the API result by default
    sortable?: boolean;
    filterable?: boolean;
    customValue?: Function;
    customLabel?: Function;
    defaultOrder?: string;
    defaultFilter?: { operator: string; expression: string };
  }>;
  detailButton?: {
    title?: string; // Title, if specified, is used in table header. Otherwise label is also used for that.
    label: string;
    width?: string;
    route: string; // can contain {id} as placeholder for identifier
    identifier: string; // where inside one entry is the identifier found (using dot operator), e.g. "attributes.id"
    position?: number; // Starting from 0, 1, 2 = first, second, third column...; -1, -2, -3 = last, second last, third last column ...
  };
  pagination?: {
    number?: number;
    defaultPageSize?: number;
    pageSizeOptions?: Array<number>;
  };
  filterOperators?: Map<string, string>;
  defaultFilterOperator?: string;
  appendix?: Object;
  hide?: Array<string>;
  checkbox?: boolean;
}

const props = defineProps<tablePropTypes>();

// define emits
const emit = defineEmits<{
  (e: 'loaded'): void;
}>();

/**
 * CUSTOM VALUES
 */
const getField = (field: string, row: any) => {
  const customFields = props.columns.reduce((prev: any, curr: any) => {
    if (curr.customValue !== undefined) {
      prev[curr.field] = curr.customValue;
    }
    return prev;
  }, new Object());
  const emptyFields = props.columns.reduce((prev: any, curr: any) => {
    if (curr.empty !== undefined && curr.empty === true) {
      prev[curr.field] = true;
    }
    return prev;
  }, new Object());

  if (field in customFields) {
    row = isProxy(row) ? toRaw(row) : row;
    let returnval = customFields[field](row, field);
    return returnval;
  } else if (field in emptyFields) {
    return '';
  } else {
    return row[field];
  }
};

/**
 * DETAILBUTTON
 */
const detailButtonPos = props.detailButton?.position ? props.detailButton.position : 0;

/** Helper function to dynamically access nested object properties */
const resolve = (path: string, obj: object) => {
  return path.split('.').reduce((prev: any, curr: string) => {
    return prev ? prev[curr] : null;
  }, obj || self);
};

const detailButtonRoute = (entry: any) => {
  if (props.detailButton?.route) {
    const baseRoute = props.detailButton.route;
    const identifier = resolve(props.detailButton.identifier, entry);
    const resolvedRoute = baseRoute.replace('{id}', identifier);
    return resolvedRoute;
  }
  return '';
};

/**
 * PAGINATION
 */
const currentPage = props.pagination?.number ? ref<number>(props.pagination.number) : ref<number>(1);
const pageSize = props.pagination?.defaultPageSize ? ref<number>(props.pagination.defaultPageSize) : ref<number>(50);
const pageSizeOptions = props.pagination?.pageSizeOptions ? props.pagination.pageSizeOptions : [10, 25, 50, 100];
const pageSizeOptionsMap: Map<number, number> = new Map(pageSizeOptions.map(obj => [obj, obj]));

watch(pageSize, (_newVal, _oldVal) => {
  currentPage.value = 1;
});

const paginationData: any = computed(() => {
  return {
    page: currentPage.value,
    pageSize: pageSize.value,
  };
});

const pagesList: any = computed<Map<number, number>>(() => {
  if (modelList.value.res.meta.pagination.pageCount) {
    return new Map([...Array(modelList.value.res.meta.pagination.pageCount).keys()].map(obj => [obj + 1, obj + 1]));
  } else {
    return new Map<number, number>();
  }
});

const showPagination: any = computed(() => {
  if (props.hide) {
    return props.hide.indexOf('pagination') < 0;
  }
  return true;
});

/**
 * SORTING
 */
const sortData: Map<string, string> = new Map<string, string>();
let sortArray: Array<string> = new Array<string>();
const sortingChanged: any = ref(false);

const sortFunction = (field: string, val: string) => {
  if (val) {
    sortData.set(field, val);
  } else {
    sortData.delete(field);
  }

  sortArray = [];
  sortData.forEach((val: string, key: string) => {
    sortArray.push(`${key}:${val}`);
  });

  currentPage.value = 1;
  sortingChanged.value = !sortingChanged.value;
};

/**
 *  FILTERING
 */
const filterOperators = props.filterOperators
  ? props.filterOperators
  : new Map([
    ['$eq', 'Equal'],
    ['$ne', 'Not equal'],
    ['$lt', 'Less than'],
    ['$gt', 'Greater than'],
    ['$containsi', 'Contains'],
    ['$notContainsi', 'Does not contain'],
    ['$startsWith', 'Starts with'],
    ['$endsWith', 'Ends with'],
    ['$null', 'Empty'],
    ['$notNull', 'Not empty'],
  ]);

// Default filters
const defaultFilterOperator = props.defaultFilterOperator ? props.defaultFilterOperator : '$containsi';
const defaultFilterValues = filterOperators.has(defaultFilterOperator) ? { operator: defaultFilterOperator, expression: '' } : undefined;

const filterData: any = <any>{};
const filterChanged: any = ref(false);

const filterFunction = (field: string, val: any) => {
  if (val) {
    if (val.expression) {
      filterData[field] = <any>{};
      filterData[field][val.operator] = val.expression;
    } else if (filterData[field]) {
      delete filterData[field];
    }
  }
  currentPage.value = 1;
  filterChanged.value = !filterChanged.value;
};

/**
 * LOCK COLUMN WIDTH AFTER FIRST LOAD
 */
const allHeaderCellsMounted = ref(0);

const headerCellMounted = (id: string) => {
  const elm = document.getElementById(id);
  if (elm) {
    //elm.style.width = elm.clientWidth + 'px';
  }
  allHeaderCellsMounted.value++;
};

/**
 * TRACK PARAM CHANGES / BUILD PARAM OBJECT
 */
const apiParams = () => {
  let params = {
    sort: sortArray,
    filters: filterData,
    pagination: paginationData.value,
  };

  params = {
    ...params,
    ...props.appendix,
  };

  return params;
};

const trackParamChanges = computed(() => {
  return {
    sort: sortingChanged.value,
    filters: filterChanged.value,
    pagination: paginationData.value,
  };
});

/**
 * ERROR HANDLING / PERFORM REQUEST
 */
const errorOpen = ref(false);
const errorMsg = ref('');
const closeError = () => {
  errorOpen.value = false;
};

const modelList = ref({
  loaded: false,
  res: <any>[],
});

const makeRequest = () => {
  const params = {
    params: apiParams(),
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
  };

  axios
    .get(`${props.endpoint}`, params)
    .then(response => {
      modelList.value.res = response.data;
      modelList.value.loaded = true;
    })
    .catch(error => {
      errorOpen.value = true;
      errorMsg.value = 'There has been a problem during the request: ' + error?.response?.data?.error?.message;
    });

  emit('loaded');
};

// Alias for makeRequest to use for refreshing the table
const refresh = makeRequest;

watch(
  trackParamChanges,
  (_newVal, _oldVal) => {
    makeRequest();
  },
  {
    immediate: true,
  }
);

const labelList: Ref<any> = computed(() => {
  let list: any = {};
  for (let i = 0; i < props.columns.length; i++) {
    const customFn: Function | undefined = () => {
      return props.columns[i].customLabel;
    };
    if (customFn) {
      list[props.columns[i].field] = customFn();
    } else {
      list[props.columns[i].field] = props.columns[i].label;
    }
  }
  return list;
});

/**
 * Checkbox Selection
 */
const entriesIds = computed(() => {
  if (!modelList.value?.res?.data) {
    return [];
  }
  return modelList.value.res.data.map((entry: any) => entry.documentId);
});

const allSelected = ref(false);
const selectedEntries: any = ref([]);

const selectAll = (val: boolean) => {
  allSelected.value = val;
  if (val) {
    selectedEntries.value = entriesIds.value.slice();
  } else if (selectedEntries.value.length === entriesIds.value.length) {
    selectedEntries.value = [];
  }
};

const selectEntry = (rowid: any, val: boolean) => {
  if (val) {
    selectedEntries.value.push(rowid);
  } else {
    selectedEntries.value.splice(selectedEntries.value.indexOf(rowid), 1);
  }

  if (selectedEntries.value.length === entriesIds.value.length) {
    allSelected.value = true;
  } else {
    allSelected.value = false;
  }
};

watch(entriesIds, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    selectedEntries.value = [];
    allSelected.value = false;
  }
});

const length = computed(() => {
  return modelList.value?.res?.data?.length;
});

defineExpose({
  refresh,
  entriesIds,
  selectedEntries,
  length,
});
</script>
<template>
  <div :class="{
    mounted: allHeaderCellsMounted >= props.columns.length + (props.detailButton ? 1 : 0),
  }">
    <template v-if="modelList.loaded">
      <TableWrapper class="w-full">
        <template v-slot:head>
          <TableRow>
            <template v-for="(item, index) in props.columns" :key="item.field">
              <TableHeaderCell :id="`tableHeaderCell_checkbox`" v-if="props.checkbox && index == 0" class="w-[50px]">
                <TableCheckBox id="check_allSelected" :checked="allSelected" @change="(val: any) => { selectAll(val) }">
                </TableCheckBox>
              </TableHeaderCell>
              <TableHeaderCell :id="`tableHeaderCellDetailButton`"
                @mounted="headerCellMounted(`tableHeaderCellDetailButton`)"
                :style="{ width: props.detailButton.width ?? '1' }"
                v-if="props.detailButton && index == detailButtonPos">
                {{ props.detailButton.title ? props.detailButton.title : props.detailButton.label }}</TableHeaderCell>
              <TableHeaderCell :id="`tableHeaderCell_${item.field}`"
                @mounted="headerCellMounted(`tableHeaderCell_${item.field}`)" :style="{ width: item.width ?? '1' }"
                :filterable="item.filterable ? true : false" :filterOperators="filterOperators" :filterFunction="(newVal: any) => {
                  filterFunction(item.field, newVal);
                }
                  " :filterValues="item.defaultFilter ? item.defaultFilter : defaultFilterValues"
                :sortable="item.sortable ? true : false" :defaultOrder="item.defaultOrder" :sortFunction="(newVal: string) => {
                  sortFunction(item.field, newVal);
                }
                  " :openPosition="index == 0 ? 'right' : undefined">
                <template
                  v-if="labelList[item.field] && Array.isArray(labelList[item.field]()) && labelList[item.field]().length == 3">
                  <div>
                    <component :is="labelList[item.field]()[0]" v-bind="labelList[item.field]()[1]"
                      v-on="labelList[item.field]()[2]" />
                  </div>
                </template>
                <template
                  v-else-if="labelList[item.field] && Array.isArray(labelList[item.field]()) && labelList[item.field]().length == 2">
                  <div>
                    <component :is="labelList[item.field]()[0]" v-bind="labelList[item.field]()[1]" />
                  </div>
                </template>
                <template
                  v-else-if="labelList[item.field] && Array.isArray(labelList[item.field]()) && labelList[item.field]().length == 1">
                  <div>
                    <component :is="labelList[item.field]()[0]" />
                  </div>
                </template>
                <template v-else>
                  <div v-html="item.label"></div>
                </template>
              </TableHeaderCell>
              <TableHeaderCell :id="`tableHeaderCellDetailButton`"
                @mounted="headerCellMounted(`tableHeaderCellDetailButton`)"
                :style="{ width: props.detailButton.width ?? '1' }"
                v-if="props.detailButton && index == props.columns.length + detailButtonPos">{{ props.detailButton.title
                  ?
                  props.detailButton.title : props.detailButton.label }}</TableHeaderCell>
            </template>
          </TableRow>
        </template>
        <template v-slot:body v-if="modelList.res.data.length > 0">
          <TableRow v-for="row in modelList.res.data" :key="row.documentId">
            <template v-for="(item, index) in props.columns" :key="item.field">
              <TableCell v-if="props.checkbox && index == 0" class="w-[50px]">
                <TableCheckBox :id="`check_${row.documentId}`" :checked="selectedEntries.indexOf(row.documentId) > -1"
                  @change="(val: boolean) => { selectEntry(row.documentId, val) }"></TableCheckBox>
              </TableCell>
              <TableCell v-if="props.detailButton && index == detailButtonPos">
                <router-link :to="detailButtonRoute(row)"
                  class="inline-flex gap-1 items-center border-b border-b-slate-400 text-slate-500 text-sm hover:border-b-slate-700 hover:text-slate-800">{{
                    props.detailButton.label }} <IconLink class="inline"></IconLink></router-link>
              </TableCell>
              <TableCell>
                <template v-for="(fieldcontent, index) in [getField(item.field, row)]" :key="index">
                  <template v-if="Array.isArray(fieldcontent) && fieldcontent.length == 3">
                    <div>
                      <component :is="fieldcontent[0]" v-bind="fieldcontent[1]" v-on="fieldcontent[2]" />
                    </div>
                  </template>
                  <template v-else-if="Array.isArray(fieldcontent) && fieldcontent.length == 2">
                    <div>
                      <component :is="fieldcontent[0]" v-bind="fieldcontent[1]" />
                    </div>
                  </template>
                  <template v-else-if="Array.isArray(fieldcontent) && fieldcontent.length == 1">
                    <div>
                      <component :is="fieldcontent[0]" />
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      <div class="overflow-hidden whitespace-nowrap text-ellipsis w-full block" v-html="fieldcontent">
                      </div>
                    </div>
                  </template>
                </template>
              </TableCell>
              <TableCell v-if="props.detailButton && index == props.columns.length + detailButtonPos">
                <router-link :to="detailButtonRoute(row)"
                  class="inline-flex gap-1 items-center border-b border-b-slate-400 text-slate-500 text-sm hover:border-b-slate-700 hover:text-slate-800">{{
                    props.detailButton.label }} <IconLink class="inline"></IconLink></router-link>
              </TableCell>
            </template>
          </TableRow>
        </template>
        <template v-slot:pagination v-if="modelList.loaded && modelList.res.data.length > 0 && showPagination">
          <div class="flex gap-4 flex-wrap items-end">
            <div>
              <div class="mr-2 mb-[2px] text-xs font-medium text-slate-800">Entries per page</div>
              <SelectBox :items="pageSizeOptionsMap" v-model="pageSize" class="w-min min-w-[7.5rem]"></SelectBox>
            </div>
            <div v-if="modelList.res.meta.pagination.pageCount > 1">
              <div class="mr-2 mb-[2px] text-xs font-medium text-slate-800">
                Page (Total: {{ modelList.res.meta.pagination.pageCount }})
              </div>
              <TablePagination v-if="modelList.res.meta.pagination.pageCount <= 10">
                <a v-for="page in pagesList.values()" :key="page" @click="currentPage = page" class="cursor-pointer">
                  <PaginationItem :active="currentPage == page">{{ page }}</PaginationItem>
                </a>
              </TablePagination>
              <div v-else class="flex gap-1">
                <!-- previous page -->
                <a @click="currentPage = currentPage - 1"
                  class="cursor-pointer px-[0.375rem] py-[0.625rem] text-slate-400 border-slate-200 shadow-sm hover:text-blue-600 hover:border-blue-600 border bg-white leading-5 text-lg rounded">
                  <IconChevronLeft></IconChevronLeft>
                </a>
                <AutoComplete :items="pagesList" v-model="currentPage" class="w-min min-w-[6.5rem]"></AutoComplete>
                <!-- next page -->
                <a @click="currentPage = currentPage + 1"
                  class="cursor-pointer px-[0.375rem] py-[0.625rem] text-slate-400 border-slate-200 shadow-sm hover:text-blue-600 hover:border-blue-600 border bg-white leading-5 text-lg rounded">
                  <IconChevronRight></IconChevronRight>
                </a>
              </div>
            </div>
            <div v-if="modelList.res.meta.pagination.total" class="ml-auto mr-1 text-sm text-slate-900 py-3">
              Total:
              <span class="font-semibold">{{ modelList.res.meta.pagination.total }}</span>
            </div>
          </div>
        </template>
      </TableWrapper>
    </template>
    <div v-if="modelList.loaded && modelList.res.data.length == 0"
      class="text-xl mt-10 flex justify-center gap-2 items-center">
      No data found
    </div>
    <div v-if="!modelList.loaded" class="text-xl mt-10 flex justify-center gap-2 items-center">
      <IconLoading class="float-left"></IconLoading> Loading data...
    </div>
    <Teleport to="body">
      <DialogPopup :open="errorOpen" @close="closeError()" :appearance="'error'" :title="'Error'">{{ errorMsg }}
      </DialogPopup>
    </Teleport>
  </div>
</template>
<style scoped>
.mounted td {
  max-width: 0;
}
</style>
