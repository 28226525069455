<script setup lang="ts">
import RecognitionStateFormatter from '@/services/RecognitionStateFormatter';
import IconRepeat from '~icons/clarity/refresh-line';
import IconTimer from '~icons/mingcute/sandglass-line';
import IconCancel from '~icons/tabler/x';
import IconLoading from '~icons/eos-icons/loading';

interface Props {
  status?: string;
  showButtons?: {
    repeatRecognition?: boolean;
    cancelRecognition?: boolean;
  };
}

const props = defineProps<Props>();

const showRepeatRecognition = computed(() => props.showButtons?.repeatRecognition && props?.status != 'unrecognized' && props?.status != 'processing');
const showCancelRecognition = computed(() => props.showButtons?.cancelRecognition && props?.status == 'unrecognized');
const showButtons = computed(() => showRepeatRecognition.value || showCancelRecognition.value);

// define emits
const emit = defineEmits<{
  (e: 'repeatRecognition'): void;
  (e: 'cancelRecognition'): void;
}>();


const emitRecognition = () => {
  emit('repeatRecognition');
};

const cancelRecognition = () => {
  emit('cancelRecognition');
};

const hover = ref(false);
</script>
<template>
  <div @mouseenter="hover = true" @mouseleave="hover = false" class="-my-2 h-[2.375rem] py-[0.437rem] relative">
    <div v-if="hover && showButtons">
      <a v-if="showRepeatRecognition" @click="emitRecognition()"
        class="cursor-pointer box-border h-6 rounded-2xl border border-slate-700 text-slate-700 inline-flex items-center px-2 text-xs mr-2 hover:text-white hover:bg-slate-700">
        <IconRepeat class="w-3 h-3 mr-1" />
        Recognize
      </a>
      <a v-if="showCancelRecognition" @click="cancelRecognition()"
        class="cursor-pointer box-border h-6 rounded-2xl border border-red-600 text-red-600 inline-flex items-center px-2 text-xs mr-2 hover:text-white hover:bg-red-600">
        <IconCancel class="w-3 h-3 mr-1" />
        Cancel
      </a>
    </div>
    <div v-else>
      <span v-if="props.status" class="inline-block py-1 px-3 text-xs text-white rounded-2xl cursor-default"
        :class="RecognitionStateFormatter.getColor(props.status)">
        <span v-if="props.status == 'unrecognized'">
          <span class="block float-left h-4 w-4 mr-1 -ml-1 animation-pause-bounce">
            <IconTimer class="w-4 h-4 scale-110 animation-pause-spin" />
          </span>
        </span>
        <span v-if="props.status == 'processing'">
          <span class="block float-left h-4 w-4 mr-1 -ml-1">
            <IconLoading class="w-4 h-4 scale-110" />
          </span>
        </span>
        {{ RecognitionStateFormatter.getLabel(props.status) }}
      </span>
      <span v-else class="p-0 text-xs text-white rounded-2xl h-6 w-6 items-center justify-center flex bg-slate-300">
        <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24">
          <path fill="currentColor" fill-rule="evenodd"
            d="M10.657 12.071L5 6.414L6.414 5l5.657 5.657L17.728 5l1.414 1.414l-5.657 5.657l5.657 5.657l-1.414 1.414l-5.657-5.657l-5.657 5.657L5 17.728z" />
        </svg>
      </span>
    </div>
  </div>
</template>
<style scoped>
.animation-pause-spin {
  animation: pause-spin 3.5s linear infinite;
}

.animation-pause-bounce {
  animation: pause-bounce 3.5s linear infinite;
}

@keyframes pause-spin {
  0% {
    transform: rotate(0deg);
  }

  4.16% {
    transform: rotate(0deg);
  }

  16.66% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  54.16% {
    transform: rotate(180deg);
  }

  66.66% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pause-bounce {
  0% {
    transform: translateY(2.5%);
  }

  5.55% {
    transform: translateY(-15%);
  }

  20% {
    transform: translateY(-15%);
  }

  25% {
    transform: translateY(2.5%);
  }

  50% {
    transform: translateY(2.5%);
  }

  55.55% {
    transform: translateY(-15%);
  }

  70% {
    transform: translateY(-15%);
  }

  75% {
    transform: translateY(2.5%);
  }

  100% {
    transform: translateY(2.5%);
  }
}
</style>