<script setup lang="ts">
import IconReload from '~icons/tabler/reload';
import axios from 'axios';
import { usePagesStore } from '@/store';
import { defineComponent } from 'vue';
import SmartTable from '@/components/common/SmartTable/SmartTable.vue';
import TableStatus from '@/components/common/table/TableStatus.vue';
//import TableTypenameSelect from '@/components/common/table/TableTypenameSelect.vue';
import TableLink from '@/components/common/table/TableLink.vue';
import TableImagesService from '@/services/TableImagesService';
import TableModelService from '@/services/TableModelService';

const pagesStore = usePagesStore();
const apiBaseUrl = import.meta.env.VITE_APIURL;
const apiServerUrl = import.meta.env.VITE_APISERVERURL;

useHead({
  title: 'Dashboard | Panel Manager',
});

pagesStore.$patch({
  currentPage: 'Dashboard',
});

const localStatus: any = ref({});

const resetLocalStatus = () => {
  localStatus.value = {};
};

const customDate = (row: any, field: string) => {
  let date: Date = new Date(row[field]);
  return (
    '<span class="font-normal">' +
    date.toLocaleTimeString('de-DE') +
    '</span> <span class="text-slate-500 text-xs">' +
    date.toLocaleDateString('de-DE') +
    '</span>'
  );
};

const customStatus = (row: any, field: string) => {
  let val: string = row?.[field];

  if (localStatus.value[row.documentId] !== undefined && localStatus.value[row.documentId] !== val) {
    val = localStatus.value[row.documentId];
  }

  // @ts-ignore
  const instance = defineComponent(TableStatus);
  return [
    instance,
    {
      status: val,
      showButtons: {
        repeatRecognition: true,
        cancelRecognition: true,
      },
    },
    {
      repeatRecognition: () => repeatRecognition(row.documentId),
      cancelRecognition: () => cancelRecognition(row.documentId),
    },
  ];
};

const repeatRecognition = (documentId: string) => {
  axios
    .get(apiBaseUrl + 'solarpanel/repeat-recognition/' + documentId)
    .then(() => {
      refreshTable();
    })
    .catch(error => {
      console.log(error);
    });

  localStatus.value[documentId] = 'unrecognized';
};

const cancelRecognition = async (documentId: string) => {
  const requestParams = {
    data: {
      recognition_status: 'recognitionFailed',
    },
  };
  await axios.put(apiBaseUrl + 'solarpanels/' + documentId, requestParams);
  localStatus.value[documentId] = 'recognitionFailed';
};

const customId = (row: any, field: string) => {
  let id: number = row?.id;
  let documentId: string = row?.documentId;
  let route: string = '/panels/edit/' + documentId;
  // @ts-ignore
  const instance = defineComponent(TableLink);
  return [instance, { route: route, label: id.toString() }];
};

const endpoint = `${apiBaseUrl}solarpanels`;
const columns = [
  {
    field: 'id',
    label: 'ID',
    width: '80px',
    sortable: false,
    filterable: false,
    customValue: customId,
    defaultOrder: 'desc',
  },
  {
    field: 'createdAt',
    label: 'Date',
    width: '170px',
    sortable: false,
    filterable: false,
    customValue: customDate,
  },
  {
    field: 'model',
    label: 'Model',
    empty: true,
    sortable: false,
    filterable: false,
    customValue: (row: any, field: string) => {
      return TableModelService.customModel(row, field, refreshTable);
    },
  },
  {
    field: 'images',
    label: 'Images',
    customValue: TableImagesService.customImages,
  },
  {
    field: 'recognition_status',
    label: 'Status',
    width: '300px',
    sortable: false,
    filterable: true,
    customValue: customStatus,
  },
];

const appendix = {
  populate: {
    recognition_images: {
      populate: {
        img: {
          populate: true,
        },
      },
    },
    panelmodel: {
      populate: true,
    },
  },
};

const hide = ['pagination'];

const tableRef = ref<InstanceType<typeof SmartTable> | null>(null);

const refreshTable = () => {
  tableRef.value?.refresh();
};

const dragover = ref(false);
</script>
<template>
  <div @dragover.prevent="dragover = true" @dragleave.prevent="dragover = false" @drop.prevent="dragover = false">
    <div
      class="flex absolute inset-x-8 inset-y-4 rounded border-dashed border-2 border-slate-300 bg-white bg-opacity-90 z-50 font-medium justify-center text-slate-900 items-center transition-all duration-150 ease-out"
      :class="{ 'opacity-100 scale-100 pointer-events-auto': dragover, 'scale-95 opacity-0 pointer-events-none': !dragover }">
      <UploadImages></UploadImages>
    </div>
    <div class="flex justify-end">
      <RecognitionsServiceStatus></RecognitionsServiceStatus>
    </div>
    <LiveWatch @dispatchedEntry="refreshTable()" @newEntry="refreshTable()" @recognitionChange="refreshTable()">
    </LiveWatch>
    <h3 class="mb-1 text-lg leading-8 inline-flex items-center justify-center font-medium text-slate-900">
      <div class="h-8 -ml-1 mr-1">
        <Tooltip content="Reload table">
          <div class="cursor-pointer text-slate-900 w-8 h-8 inline-flex items-center justify-center"
            @click="refreshTable()">
            <IconReload class="w-6 h-6"></IconReload>
          </div>
        </Tooltip>
      </div>
      Recently processed:
    </h3>
    <SmartTable ref="tableRef" :endpoint="endpoint" :columns="columns" :appendix="appendix" :hide="hide"
      @loaded="resetLocalStatus()"></SmartTable>
  </div>
</template>
<route lang="yaml">
name: dashboard
meta:
  layout: backend
</route>
