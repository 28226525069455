<script setup lang="ts"></script>
<template>
  <td class="border-t border-slate-200 whitespace-normal max-w-sm">
    <div
      class="table-content-cell py-2 pl-4 text-left text-slate-700 whitespace-nowrap relative focus-within:z-20 text-ellipsis block max-w-full min-h-[2.25rem]"
    >
      <slot></slot>
    </div>
  </td>
</template>
<style>
  .table-content-cell > div {
    display: inline;
  }
</style>
