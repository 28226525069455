<script setup lang="ts">
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/vue';
import { Ref } from 'vue';
import CheckIcon from '~icons/bx/check';
import SelectorIcon from '~icons/gg/select';
import IconCancel from '~icons/fe/close';
import axios from 'axios';

const apiBaseUrl = import.meta.env.VITE_APIURL;

interface SelectElements {
  modelValue?: string;
  cancelButton?: boolean;
}

const props = defineProps<SelectElements>();
const emit = defineEmits(['update:modelValue', 'focus']);

const selectedItem: Ref<string> = ref(props.modelValue ?? '');

watch(
  () => props.modelValue,
  () => {
    selectedItem.value = props.modelValue ?? '';
  }
);

watch(
  selectedItem,
  (newVal, _oldVal) => {
    emit('update:modelValue', newVal);
  },
  { immediate: true }
);

const panelList: any = ref([]);

const comPanelModels = computed(() => {
  if (!panelList.value) {
    return [];
  }
  return panelList.value
    .map((item: any) => {
      return {
        id: item.documentId,
        manufacturer: item.manufacturer,
        typename: item.typename,
      };
    })
    .reduce((result: any, current: any) => {
      result[current.id] = current.typename + ', ' + current.manufacturer;
      return result;
    }, new Object());
});

// return panellist filtered by typename search string using axios
const filterPanelList = async (searchString: string) => {
  if (searchString && searchString.length > 0) {
    // get first part of searchstring split by comma
    const searchType = searchString.split(',')[0].trim();

    let response = await axios.get(
      apiBaseUrl +
      'panelmodels/?filters[$or][0][typename][$contains]=' +
      searchString +
      '&filters[$or][1][typename][$contains]=' +
      searchType +
      '&filters[$or][2][manufacturer][$contains]=' +
      searchString
    );
    panelList.value = response.data.data;
  } else {
    let response = await axios.get(apiBaseUrl + 'panelmodels/');
    panelList.value = response.data.data;
  }
};

// get panelmodel by props.modelValue
const getPanelModel = async (id: string) => {
  if (id) {
    let response = await axios.get(apiBaseUrl + 'panelmodels/' + id);
    await filterPanelList(response.data.data.typename);
    return response.data.data.typename + ', ' + response.data.data.manufacturer;
  } else {
    await filterPanelList('');
  }
};

const query = ref(props.modelValue ? await getPanelModel(props.modelValue) : '');

const displayValue = computed(() => {
  return comPanelModels.value[selectedItem.value] ?? query.value;
});

const clearInput = () => {
  selectedItem.value = '';
  query.value = '';
  filterPanelList('');
};
</script>
<template>
  <div class="w-full">
    <Combobox v-model="selectedItem">
      <div class="relative">
        <ComboboxInput class="input-text" @change="e => {
          query = e.target.value;
          filterPanelList(query);
        }
          " @focus="emit('focus')" :displayValue="() => displayValue">
          <ComboboxButton
            class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none outline-hidden border-none">
            <SelectorIcon class="w-5 h-5 text-slate-400" aria-hidden="true" />
          </ComboboxButton>
        </ComboboxInput>
        <div v-if="props.cancelButton && selectedItem"
          class="absolute inset-y-0 right-0 flex items-center outline-hidden border-none z-10">
          <button @click="clearInput()"
            class="text-slate-400 hover:text-slate-500 px-2 cursor-pointer h-full focus:outline-none focus:text-slate-500 transition duration-150 ease-in-out">
            <IconCancel class="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
          leave-to-class="opacity-0">
          <ComboboxOptions
            class="absolute w-full bg-white rounded-md py-2 shadow-centered sm:text-sm focus:outline-hidden z-50 max-h-64 overflow-auto mt-[1px] -ml-[1px]">
            <ComboboxOption v-for="(item, key) in comPanelModels" :key="key" :value="key" v-slot="{ selected, active }"
              as="template">
              <li
                :class="[active ? 'bg-slate-50' : '', 'text-slate-900 cursor-default select-none relative py-2 pl-10 pr-4']">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate leading-5 h-5']">{{ item
                  }}</span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-slate-600">
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </transition>
      </div>
    </Combobox>
  </div>
</template>
<style scoped>
ul {
  width: calc(100% + 2px);
}
</style>
