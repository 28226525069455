<script setup lang="ts">
  const props = defineProps({
    route: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  });

  defineEmits([]);
</script>
<template>
  <router-link
    :to="props.route"
    class="hover:underline py-2 pl-4 -my-2 -ml-4 inline-block box-content w-full text-slate-700 hover:text-slate-900"
  >
    {{ props.label }}
  </router-link>
</template>
