<script setup lang="ts">
import IconLoading from '~icons/eos-icons/loading';
import IconChecked from '~icons/akar-icons/circle-check-fill';
import IconFailed from '~icons/akar-icons/circle-x-fill';

interface LiveImageProps {
  state: string | null;
}

const props = defineProps<LiveImageProps>();
</script>
<template>
  <p v-if="props.state == 'processing'" class="flex items-center cursor-default">
    <IconLoading class="text-slate-600 scale-110"></IconLoading>
    <span class="text-slate-700 ml-2">Processing</span>
  </p>
  <p v-if="props.state == 'success'" class="flex items-center cursor-default">
    <IconChecked class="text-green-700 scale-110"></IconChecked>
    <span class="text-slate-700 ml-2">Success</span>
  </p>
  <p v-if="props.state == 'bestmatch'" class="flex items-center cursor-default">
    <IconChecked class="text-green-700 scale-110"></IconChecked>
    <span class="text-green-700 font-medium ml-2">Best match</span>
  </p>
  <p v-if="props.state == 'recognitionFailed' || props.state == null" class="flex items-center cursor-default">
    <IconFailed class="text-slate-600 scale-110"></IconFailed>
    <span class="text-slate-700 ml-2">Failed</span>
  </p>
  <p v-if="props.state == 'emptyResult'" class="flex items-center cursor-default">
    <IconFailed class="text-slate-600 scale-110"></IconFailed>
    <span class="text-slate-700 ml-2">No label found</span>
  </p>
</template>
