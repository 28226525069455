<script setup lang="ts">
  import { useSlots } from 'vue';

  const hasPagination = () => {
    const paginationSlot = useSlots().pagination;
    return !!paginationSlot;
  };

  const hasHead = () => {
    const headSlot = useSlots().head;
    return !!headSlot;
  };

  const hasBody = () => {
    const bodySlot = useSlots().body;
    return !!bodySlot;
  };
</script>
<template>
  <div>
    <div v-if="hasPagination()" class="mb-5">
      <slot name="pagination"></slot>
    </div>
    <table
      cellpadding="0"
      cellspacing="0"
      class="w-full bg-white border-separate border border-slate-200 rounded relative text-sm mb-5"
      style="border-spacing: 0"
    >
      <thead v-if="hasHead()" class="bg-slate-50">
        <slot name="head"></slot>
      </thead>
      <tbody v-if="hasBody()">
        <slot name="body"></slot>
      </tbody>
    </table>
    <div v-if="hasPagination()" class="mb-5">
      <slot name="pagination"></slot>
    </div>
  </div>
</template>
