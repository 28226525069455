<script setup lang="ts">
  const props = defineProps({
    active: Boolean,
  });
</script>
<template>
  <div
    tabindex="-1"
    class="pagination-item relative min-w-[2.875rem] p-3 text-center bg-white border border-slate-200 -mr-[1px] text-slate-900 text-sm"
    v-bind:class="{
      'border-slate-400 z-10 bg-slate-50 pagination-item-active outline-none focus:ring-1 focus:ring-slate-400': props.active,
      'border-slate-200': !props.active,
    }"
  >
    <slot></slot>
  </div>
</template>
<style>
  .table-pagination > *:last-child .pagination-item,
  .table-pagination > .pagination-item:last-child {
    @apply rounded-r;
  }
  .table-pagination > *:first-child .pagination-item,
  .table-pagination > .pagination-item:last-child {
    @apply rounded-l;
  }
  .table-pagination a .pagination-item:not(.pagination-item-active):hover {
    @apply bg-slate-50;
  }
</style>
