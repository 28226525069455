<script setup lang="ts">
  import { Ref } from 'vue';

  interface CheckBoxProps {
    id: string | number;
    checked: boolean;
  }
  const props = defineProps<CheckBoxProps>();

  const checked: Ref<boolean> = ref(props.checked ? props.checked : false);

  watch(
    () => props.checked,
    value => {
      checked.value = value;
    }
  );

  const id: Ref<string> = ref('');
  if (typeof props.id === 'number') {
    id.value = 'id-' + props.id.toString();
  } else {
    id.value = props.id;
  }

  const emit = defineEmits(['change']);

  const toggle = () => {
    checked.value = !checked.value;
    emit('change', checked.value);
  };
</script>
<template>
  <label class="p-4 -m-4">
    <input :id="id" :name="id" type="checkbox" class="checkbox" :checked="checked" @change="toggle()" />
  </label>
</template>
