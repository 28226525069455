export default class RecognitionStateFormatter {
  private static instance: RecognitionStateFormatter;
  private static colors: { [key: string]: Array<string> } = {
    unrecognized: ['Unrecognized', 'bg-zinc-400'],
    autoRecognized: ['Autorecognition', 'bg-green-700'],
    manuallyRecognized: ['Manual recognition', 'bg-green-700'],
    recognitionFailed: ['Recognition failed', 'bg-red-600'],
    recognitionDeactivated: ['Recognition deactivated', 'bg-zinc-400'],
    processing: ['Processing', 'bg-blue-600'],
    confirmed: ['Confirmed', 'bg-green-700'],
  };

  public static getLabel(state: string): string {
    return RecognitionStateFormatter.colors[state][0];
  }
  
  public static getColor(state: string): string {
    return RecognitionStateFormatter.colors[state][1];
  }


}