<script setup lang="ts">
  import { Popover, PopoverButton } from '@headlessui/vue';
  import IconSortAsc from '~icons/clarity/sort-by-line';
  import IconAddFilter from '~icons/clarity/filter-line';
  import IconHasFilter from '~icons/clarity/filter-solid';

  interface propTypes {
    filterable?: boolean;
    filterOperators?: Map<string, string>;
    filterValues?: { operator: string; expression: string };
    filterFunction?: Function;
    sortable?: boolean;
    defaultOrder?: string;
    sortFunction?: Function;
    openPosition?: 'left' | 'center' | 'right';
  }

  const props = defineProps<propTypes>();
  const emit = defineEmits(['mounted']);

  const currentOrder = props.defaultOrder ? ref(props.defaultOrder) : ref('');

  const filterData = ref({ operator: '', expression: '' });
  if (props.filterValues) {
    filterData.value = props.filterValues;
  }

  const sortOptions = new Array('', 'asc', 'desc');

  const showSortAsc = computed(() => {
    return currentOrder.value == 'asc' && props.sortable;
  });
  const showSortDesc = computed(() => {
    return currentOrder.value == 'desc' && props.sortable;
  });
  const showFilterOption = computed(() => {
    return props.filterable && props.filterOperators;
  });
  const hasFilter = computed(() => {
    if (!!filterData.value) {
      return filterData.value.expression.length > 0;
    } else {
      return false;
    }
  });

  watch(
    currentOrder,
    (newVal, _oldVal) => {
      if (!!props.sortFunction) {
        props.sortFunction(newVal);
      }
    },
    { immediate: true }
  );

  watch(
    filterData,
    (newVal, _oldVal) => {
      if (!!props.filterFunction) {
        props.filterFunction(newVal);
      }
    },
    { immediate: true }
  );

  const changeCurrentOrder = () => {
    if (currentOrder.value !== undefined && props.sortable) {
      currentOrder.value = sortOptions[(sortOptions.indexOf(currentOrder.value) + 1) % sortOptions.length];
    }
  };

  onMounted(() => {
    emit('mounted');
  });
</script>
<template>
  <th
    class="bg-slate-50 min-h-[3rem] py-2 px-4 font-medium text-base leading-6 p-3 text-left text-slate-900 border-b border-b-slate-100 border-r border-r-slate-200 last:border-r-0 align-middle"
    v-bind:title="showSortAsc ? 'Sorted ascending' : showSortDesc ? 'Sorted descending' : ''"
  >
    <div v-if="props.filterable && props.filterOperators" class="relative block float-right ml-2 py-1 cursor-pointer">
      <Popover as="div" class="relative z-30">
        <PopoverButton as="a" class="block relative">
          <template v-if="hasFilter">
            <IconHasFilter class="w-auto h-6 py-[2px] pr-[2px]"></IconHasFilter>
            <div class="bg-orange-600 w-2 h-2 rounded-full absolute right-0 top-0"></div>
          </template>
          <IconAddFilter v-else class="w-auto h-6 py-[2px] pr-[2px]"></IconAddFilter>
        </PopoverButton>
        <PopoutFilter v-model="filterData" :operators="props.filterOperators" :openPosition="props.openPosition"></PopoutFilter>
      </Popover>
    </div>
    <a
      @click="changeCurrentOrder()"
      class="block select-none py-1"
      :class="{
        'mr-8': showFilterOption,
        'cursor-pointer': props.sortable,
        'pr-8': !showSortAsc && !showSortDesc && props.sortable,
      }"
    >
      <IconSortAsc class="block float-left mr-2 w-auto h-6 -scale-y-100" title="Sorted ascending" v-if="showSortAsc"></IconSortAsc>
      <IconSortAsc class="block float-left mr-2 w-auto h-6" title="Sorted descending" v-if="showSortDesc"></IconSortAsc>
      <slot></slot>
    </a>
  </th>
</template>
