const __pages_import_0__ = () => import("/src/pages/panels/index.vue");
const __pages_import_1__ = () => import("/src/pages/panels/add.vue");
const __pages_import_2__ = () => import("/src/pages/models/index.vue");
const __pages_import_3__ = () => import("/src/pages/models/import.vue");
const __pages_import_4__ = () => import("/src/pages/models/add.vue");
const __pages_import_5__ = () => import("/src/pages/resetpassword.vue");
const __pages_import_6__ = () => import("/src/pages/logout.vue");
const __pages_import_7__ = () => import("/src/pages/login.vue");
import __pages_import_8__ from "/src/pages/index.vue";
const __pages_import_9__ = () => import("/src/pages/forgotpassword.vue");
const __pages_import_10__ = () => import("/src/pages/[...all].vue");
const __pages_import_11__ = () => import("/src/pages/models/edit/[id].vue");
const __pages_import_12__ = () => import("/src/pages/panels/edit/[id].vue");

const routes = [{"name":"panels","path":"/panels","component":__pages_import_0__,"props":true,"meta":{"layout":"backend"}},{"name":"panel-add","path":"/panels/add","component":__pages_import_1__,"props":true,"meta":{"layout":"backend"}},{"name":"models","path":"/models","component":__pages_import_2__,"props":true,"meta":{"layout":"backend"}},{"name":"model-import","path":"/models/import","component":__pages_import_3__,"props":true,"meta":{"layout":"backend"}},{"name":"model-add","path":"/models/add","component":__pages_import_4__,"props":true,"meta":{"layout":"backend"}},{"name":"resetpassword","path":"/resetpassword","component":__pages_import_5__,"props":true,"redirect":"/","meta":{"layout":"login"}},{"name":"logout","path":"/logout","component":__pages_import_6__,"props":true,"meta":{"layout":"login"}},{"name":"login","path":"/login","component":__pages_import_7__,"props":true,"meta":{"layout":"login"}},{"name":"dashboard","path":"/","component":__pages_import_8__,"props":true,"meta":{"layout":"backend"}},{"name":"forgotpassword","path":"/forgotpassword","component":__pages_import_9__,"props":true,"redirect":"/","meta":{"layout":"login"}},{"name":"not-found","path":"/:all(.*)*","component":__pages_import_10__,"props":true,"meta":{"layout":404}},{"name":"model-edit","path":"/models/edit/:id","component":__pages_import_11__,"props":true,"meta":{"layout":"backend","backButton":true}},{"name":"panel-edit","path":"/panels/edit/:id","component":__pages_import_12__,"props":true,"meta":{"layout":"backend","backButton":true}}];

export default routes;