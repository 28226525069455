import App from '@/App.vue';
import { ViteSSG } from 'vite-ssg';
import { setupLayouts } from 'virtual:generated-layouts';
import type { UserModule } from './types';
import { useAuthStore } from '@/store';
import axios from 'axios';
import generatedRoutes from '~pages';

import '@/styles/index.css';
import '@/styles/inter-font.css';

const routes = setupLayouts(generatedRoutes);

export const createApp = ViteSSG(App, { routes, base: import.meta.env.BASE_URL }, async ctx => {
  Object.values(
    import.meta.glob<{ install: UserModule }>('./modules/*.ts', {
      eager: true,
    })
  ).map(i => i.install?.(ctx));

  ctx.router.beforeEach((to, from) => {
    const authStore: any = useAuthStore();
    if (!authStore.ready && ctx.isClient && sessionStorage) {
      authStore.$patch({
        authenticated: sessionStorage.getItem('jwt') ? true : false,
      });
    }

    if (!authStore.authenticated) {
      if (typeof to.name !== 'string' || ['logout', 'login', 'forgotpassword', 'resetpassword'].indexOf(to.name) == -1) {
        ctx.router.push('/login');
      }
    } else {
      if (typeof to.name === 'string' && ['login', 'forgotpassword', 'resetpassword'].indexOf(to.name) > -1) {
        ctx.router.push('/');
      }
    }
  });

  axios.interceptors.request.use(
    config => {
      const token = sessionStorage.getItem('jwt');
      if (token) {
        if (config.headers !== undefined) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    config => {
      return config;
    },
    error => {
      console.log(error);
      if (error?.response?.data?.error?.status == 401 || error?.response?.data?.error?.status == 403) {
        ctx.router.push('/logout?autologout=true');
      }
      if (!error?.response?.data) {
        console.log('No response from server');
      }

      return Promise.reject(error);
    }
  );
});
