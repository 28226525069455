<script setup lang="ts">
  // Wrapper for Popper: Imports vue3-popper and offers the same props as the original component, but with other default values
  import Popper from 'vue3-popper';

  type PlacementTypes =
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';

  interface TooltipProps {
    placement?: PlacementTypes;
    disableClickAway?: boolean;
    offsetSkid?: string;
    offsetDistance?: string;
    hover?: boolean;
    show?: boolean;
    disabled?: boolean;
    openDelay?: number | string;
    closeDelay?: number | string;
    zIndex?: number | string;
    arrow?: boolean;
    arrowPadding?: string;
    interactive?: boolean;
    locked?: boolean;
    content?: string;
  }

  const props = defineProps<TooltipProps>();

  const properties = ref({
    placement: props.placement || <PlacementTypes>'top',
    disableClickAway: props.disableClickAway || false,
    offsetSkid: props.offsetSkid || '0',
    offsetDistance: props.offsetDistance || '8',
    hover: props.hover || true,
    show: props.show || undefined,
    disabled: props.disabled || false,
    openDelay: props.openDelay || 0,
    closeDelay: props.closeDelay || 0,
    zIndex: props.zIndex || 9999,
    arrow: props.arrow || true,
    arrowPadding: props.arrowPadding || '0',
    interactive: props.interactive || true,
    locked: props.locked || false,
    content: props.content || undefined,
  });

  const emit = defineEmits(['close:popper', 'open:popper']);

  const theme = ref('light');
</script>
<template>
  <div>
    <Popper
      :class="theme"
      :placement="properties.placement"
      :disable-click-away="properties.disableClickAway"
      :offset-skid="properties.offsetSkid"
      :offset-distance="properties.offsetDistance"
      :hover="properties.hover"
      :show="properties.show"
      :disabled="properties.disabled"
      :open-delay="properties.openDelay"
      :close-delay="properties.closeDelay"
      :z-index="properties.zIndex"
      :arrow="properties.arrow"
      :arrow-padding="properties.arrowPadding"
      :interactive="properties.interactive"
      :locked="properties.locked"
      :content="properties.content"
      @close:popper="emit('close:popper')"
      @open:popper="emit('open:popper')"
    >
      <slot></slot>
      <template #content>
        <slot name="content"></slot>
      </template>
    </Popper>
  </div>
</template>
<style scoped>
  :deep(.popper) {
    @apply px-3 py-2 rounded shadow-sm font-normal text-xs;
  }
  :deep(.light .popper) {
    font-size: 0.875rem;
    @apply bg-slate-900 text-white;
  }
  :deep(.light .popper #arrow::before) {
    @apply bg-slate-900;
  }
  :deep(.light .popper:hover),
  :deep(.light .popper:hover > #arrow::before) {
    @apply bg-slate-900;
  }

  :deep(.dark .popper) {
    font-size: 0.875rem;
    @apply bg-slate-50 text-slate-900;
  }
  :deep(.dark .popper #arrow::before) {
    @apply bg-slate-50;
  }
  :deep(.dark .popper:hover),
  :deep(.dark .popper:hover > #arrow::before) {
    @apply bg-slate-50;
  }
</style>
